<template>
    <div>
        <div class="h-handle-bg">
            <div class="h-handle-button">
                <p class="h-return">
                    <el-button type="text" size="small" @click="$parent.checkSaveStatus">返回</el-button>
                </p>
                <div class="h-b"><el-button type="text" size="small" @click="saveClassify"
                        v-right-code="'Productclassify:Edit'">保存</el-button> </div>
            </div>
        </div>
        <div class="form-list">
            <el-form ref="_productClassifyForm" :model="dataSource" :rules="classfiyCheckRule">
                <el-form-item>
                    <el-col :span="3" class="form-title"><span style="color:red;">*</span>分类名称：</el-col>
                    <el-col :span="7">
                        <el-form-item prop="Name">
                            <el-input v-model.trim="dataSource.Name" :disabled="isDisabled" :readonly="isDisabled"
                                placeholder="分类名称"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="3" class="form-title"><span style="color:red;">*</span>分类编号：</el-col>
                    <el-col :span="7" v-if="!isDisabled">
                        <el-form-item>
                            <el-input v-model.trim="dataSource.Code" placeholder="分类编号" readonly disabled></el-input>
                        </el-form-item>
                    </el-col>
                </el-form-item>

                <el-form-item>
                    <el-col :span="3" class="form-title">所属分类：</el-col>
                    <el-col :span="7">
                        <el-form-item prop="ParentId">
                            <el-cascader v-model="dataSource.ParentId" :options="selectOptions"
                                :props="{ checkStrictly: true,emitPath:false }" clearable filterable></el-cascader>
                        </el-form-item>
                    </el-col>
                    <el-col :span="3" class="form-title">
                        分类描述：
                    </el-col>
                    <el-col :span="7">
                        <el-form-item prop="Remark">
                            <el-input v-model="dataSource.Remark" :maxlength="200" placeholder="分类描述"></el-input>
                        </el-form-item>
                    </el-col>
                </el-form-item>
            </el-form>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        var checkName = function (rule, value, callback) {
            if (!value) return callback(new Error('分类名称不能为空'));
            callback();
        };
        return {
            treeVal: [],
            isDisabled: false,
            //pubtreeVal: [],
            selectOptions: [],
            classfiyCheckRule: {
                Name: [{
                    validator: checkName,
                    trigger: 'blur'
                }]
            }
        }
    },
    props: {
        config: {
            isDetailDisplay: false,
        },
        dataSource: {}
    },
    mounted() {
        this.Event.$on("clearEditClassifyForm", () => this.resetForm);
        this.productCategoryTreeFn();
    },
    methods: {
        resetForm() {
            this.$refs['_productClassifyForm'].resetFields();
            this.productCategoryTreeFn();
        },
        productCategoryTreeFn() {
            var _this = this;
            this.$ajax.query("omsapi/productclassify/trees", "get", {}, (data) => {
                if (data.IsSuccess) {
                    _this.selectOptions = _this.Utils.iterationDelateMenuChildren(data.Result);
                    _this.treeVal = data.Result;
                } else {
                    _this.Utils.messageBox("获取数据失败.", "error");
                }
            });
        },
        getEmpty(){
            var _this=this;
            this.$ajax.query("omsapi/productclassify/getEmpty", "get", {}, (data) => {   
                console.log(data)
                _this.$parent.syncDataSource(data.Result);
            });
        },
        saveClassify() {
            var _this = this;
            _this.$refs["_productClassifyForm"].validate((valid) => {
                if (valid) {
                    var action=_this.dataSource.Id&&_this.dataSource.Id>0?"edit":"create";
                    _this.$ajax.send("omsapi/productclassify/"+action, "post", _this.dataSource, (data) => {
                        _this.getEmpty();
                        _this.Event.$emit("reloadTreesList");
                        _this.Utils.messageBox("保存成功.", "success");
                    });
                } else {
                    return false;
                }
            });
        },
    }
};
</script>